export type RolesTypes =
  | 'ADMIN'
  | 'FUNCIONARIO'
  | 'GERENTE'
  | 'ASSISTENTE'
  | 'AGENTE';
export interface Item {
  label: string;
  icon: string;
  route: string;
  roles: RolesTypes[];
  subMenu?: Item[];
  checkRoute?: string;
}

export interface Menu {
  title: string;
  icon: string;
  roles: RolesTypes[];
  children: Item[];
  checkRoute: string;
}

export interface Nav {
  items: (Item | Menu)[];
}

export const dashboard: Item = {
  label: 'Dashboard',
  icon: 'GrLineChart',
  route: '/dashboard',
  roles: ['ADMIN', 'FUNCIONARIO'],
};

const cadastros: Menu = {
  title: 'Cadastro PF/PJ',
  icon: 'MdPersonAdd',
  roles: ['ADMIN', 'FUNCIONARIO'],
  checkRoute: 'cadastros',
  children: [
    {
      icon: 'MdPersonAdd',
      route: '/cadastros/pessoa-fisica',
      label: 'Pessoa Física',
      roles: ['ADMIN'],
    },
    {
      icon: 'MdPersonAdd',
      route: '/cadastros/pessoa-juridica',
      label: 'Pessoa Jurídica',
      roles: ['ADMIN'],
    },
  ],
};

const cadastrosoperacional: Menu = {
  title: 'Cadastro Operacional',
  icon: 'MdAddBox',
  roles: ['ADMIN', 'FUNCIONARIO'],
  checkRoute: 'cadastros-operacional',
  children: [
    {
      icon: '',
      route: '/cadastros-operacional/agencia',
      label: 'Agências',
      roles: ['ADMIN'],
    },
    {
      icon: 'FaUserTie',
      route: '/cadastros/chaves-j',
      label: 'Chaves J',
      roles: ['ADMIN'],
    },
    {
      icon: 'FaUserTie',
      route: '/cadastros/convenios',
      label: 'Convênios',
      roles: ['ADMIN'],
    },
    {
      icon: 'FaUserTie',
      route: '/cadastros/convenios-grupos',
      label: 'Convênios grupos',
      roles: ['ADMIN'],
    },
    {
      icon: 'FaUserTie',
      route: '/cadastros-operacional/linhas',
      label: 'Linha de Crédito',
      roles: ['ADMIN'],
    },
    {
      icon: 'FaUserTie',
      route: '/cadastros-operacional/periodos',
      label: 'Períodos',
      roles: ['ADMIN'],
    },
    {
      icon: 'FaUserTie',
      route: '/cadastros-operacional/retorno',
      label: 'Retorno',
      roles: ['ADMIN'],
    },
  ],
};
const cadastroregional: Menu = {
  title: 'Cadastro Regional',
  icon: 'MdBusiness',
  roles: ['ADMIN', 'FUNCIONARIO'],
  checkRoute: 'cadastro-regional',
  children: [
    {
      icon: 'FaUserTie',
      route: '/cadastro-regional/regional',
      label: 'Regional',
      roles: ['ADMIN'],
    },
    {
      icon: 'FaUserTie',
      route: '/cadastro-regional/regional-cdc',
      label: 'Regional Meta CDC',
      roles: ['ADMIN'],
    },
  ],
};
const clientes: Menu = {
  title: 'Clientes',
  icon: 'MdPeople',
  roles: ['ADMIN', 'FUNCIONARIO'],
  checkRoute: 'clientes',
  children: [
    {
      icon: 'FaUserTie',
      route: '/clientes/clientes-pf',
      label: 'Clientes PF',
      roles: ['ADMIN'],
    },
    // {
    //   icon: 'FaUserTie',
    //   route: '/clientes/clientes-pj',
    //   label: 'Clientes PJ',
    //   roles: ['ADMIN'],
    // },
    // {
    //   icon: 'FaUserTie',
    //   route: '/clientes/encarteiramento',
    //   label: 'Encarteiramento',
    //   roles: ['ADMIN'],
    // },
    // {
    //   icon: 'FaUserTie',
    //   route: '/clientes/naoperturbe',
    //   label: 'Cadastro NÃO PERTURBE',
    //   roles: ['ADMIN'],
    // },
  ],
};
// const documentos: Menu = {
//   title: 'Documentos',
//   icon: 'GiFiles',
//   roles: ['ADMIN', 'FUNCIONARIO'],
//   checkRoute: 'documentos',
//   children: [
//     {
//       icon: 'FaUserTie',
//       route: '/documentos/cadastrar',
//       label: 'Cadastrar',
//       roles: ['ADMIN'],
//     },
//     {
//       icon: 'FaUserTie',
//       route: '/documentos/documentos-apoio',
//       label: 'Documentos Apoio',
//       roles: ['ADMIN'],
//     },
//     {
//       icon: 'FaUserTie',
//       route: '/documentos/informativos',
//       label: 'Informativos',
//       roles: ['ADMIN'],
//     },
//   ],
// };
// const financeiro: Menu = {
//   title: 'Financeiro',
//   icon: 'RiExchangeDollarLine',
//   roles: ['ADMIN', 'FUNCIONARIO'],
//   checkRoute: 'financeiro',
//   children: [
//     {
//       icon: 'FaUserTie',
//       route: '/financeiro/contasareceber',
//       label: 'Contas a Receber',
//       roles: ['ADMIN'],
//     },
//     {
//       icon: 'FaUserTie',
//       route: '/financeiro/contasapagar',
//       label: 'Contas a Pagar',
//       roles: ['ADMIN'],
//     },
//     {
//       icon: 'FaUserTie',
//       route: '/financeiro/centrodecusto',
//       label: 'Centro de Custo',
//       roles: ['ADMIN'],
//     },
//     {
//       icon: 'FaUserTie',
//       route: '/financeiro/lancamentos',
//       label: 'Lançamentos',
//       roles: ['ADMIN'],
//     },
//   ],
// };
// const gestao: Menu = {
//   title: 'Gestão',
//   icon: 'RiUserSettingsLine',
//   roles: ['ADMIN', 'FUNCIONARIO'],
//   checkRoute: 'gestao',
//   children: [
//     {
//       icon: 'FaUserTie',
//       route: '/gestao/acesso-configuracoes',
//       label: 'Acesso configurações',
//       roles: ['ADMIN'],
//     },
//     {
//       icon: 'FaUserTie',
//       route: '/gestao/dre',
//       label: 'D.R.E',
//       roles: ['ADMIN'],
//     },
//     {
//       icon: 'FaUserTie',
//       route: '/gestao/rob',
//       label: 'R.O.B',
//       roles: ['ADMIN'],
//     },
//     {
//       icon: 'FaUserTie',
//       route: '/gestao/recalcularemuneracao',
//       label: 'Recalcula Remuneração',
//       roles: ['ADMIN'],
//     },
//     {
//       icon: 'FaUserTie',
//       route: '/gestao/transferencia',
//       label: 'Transferência de Operações',
//       roles: ['ADMIN'],
//     },
//   ],
// };
// const mailing: Menu = {
//   title: 'Mailing',
//   icon: 'RiCustomerService2Line',
//   roles: ['ADMIN', 'FUNCIONARIO'],
//   checkRoute: 'mailing',
//   children: [
//     {
//       icon: 'FaUserTie',
//       route: '/mailing/malings',
//       label: 'Mailings',
//       roles: ['ADMIN'],
//     },
//     {
//       icon: 'FaUserTie',
//       route: '/mailing/gestormailing',
//       label: 'Gestor Mailing',
//       roles: ['ADMIN'],
//     },
//   ],
// };
const produtos: Menu = {
  title: 'Produtos',
  icon: 'FaProductHunt',
  roles: ['ADMIN', 'FUNCIONARIO'],
  checkRoute: 'produtos',
  children: [
    {
      icon: 'FaUserTie',
      route: '/produtos/credito-pf',
      label: 'Crédito PF',
      roles: ['ADMIN'],
    },
    {
      icon: 'FaUserTie',
      route: '/produtos/consorcio',
      label: 'Consórcio',
      roles: ['ADMIN'],
    },
    // {
    //   icon: 'FaUserTie',
    //   route: '/produtos/imobiliario',
    //   label: 'Imobiliário',
    //   roles: ['ADMIN'],
    // },
    // {
    //   icon: 'FaUserTie',
    //   route: '/produtos/cielo',
    //   label: 'Cielo',
    //   roles: ['ADMIN'],
    // },
    // {
    //   icon: 'FaUserTie',
    //   route: '/produtos/OuroCap',
    //   label: 'OuroCap',
    //   roles: ['ADMIN'],
    // },
  ],
};
const taxas: Menu = {
  title: 'Taxas Remuneração',
  icon: 'FaPercent',
  roles: ['ADMIN', 'FUNCIONARIO'],
  checkRoute: 'taxas',
  children: [
    {
      icon: '',
      route: '/taxas/agente-cdc',
      label: 'Agente CDC',
      roles: ['ADMIN'],
    },
    {
      icon: '',
      route: '/taxas/agente-contas',
      label: 'Agente Contas',
      roles: ['ADMIN'],
    },
    {
      icon: '',
      route: '/taxas/cdc-seguro-agente',
      label: 'Agente Seguro',
      roles: ['ADMIN'],
    },
    {
      icon: '',
      route: '/taxas/empresa-cdc',
      label: 'Empresa CDC',
      roles: ['ADMIN'],
    },
    {
      icon: '',
      route: '/taxas/empresa-contas',
      label: 'Empresa Contas',
      roles: ['ADMIN'],
    },
    {
      icon: '',
      route: '/taxas/cadastro-remuneracao/empresa-consorcio',
      label: 'Empresa Consórcio',
      roles: ['ADMIN'],
    },
    {
      icon: '',
      route: '/taxas/prt-taxa',
      label: '% PRT Empresa',
      roles: ['ADMIN'],
    },
    {
      icon: '',
      route: '/taxas/nivel-agente',
      label: 'Nível Agente',
      roles: ['ADMIN'],
    },
  ],
};

const relatorios: Menu = {
  title: 'Relatórios',
  icon: 'FaFile',
  roles: ['ADMIN', 'FUNCIONARIO'],
  checkRoute: 'relatorios',
  children: [
    {
      icon: 'FaUserTie',
      route: '/relatorios/relatorio-cdc',
      label: 'Crédito PF',
      roles: ['ADMIN'],
    },
    {
      icon: 'FaUserTie',
      route: '/relatorios/relatorio-consorcio',
      label: 'Corsórcio',
      roles: ['ADMIN'],
    },
    // {
    //   icon: 'FaUserTie',
    //   route: '/relatorios/relatorio-cdc1',
    //   label: 'Producao Agente',
    //   roles: ['ADMIN'],
    // },
    // {
    //   icon: 'FaUserTie',
    //   route: '/relatorios/relatorio-consorcio2',
    //   label: 'Produção parceiro',
    //   roles: ['ADMIN'],
    // },

    // {
    //   icon: 'FaUserTie',
    //   route: '/relatorios/relatorio-consorcio5',
    //   label: 'Extrato Pgto Agente',
    //   roles: ['ADMIN'],
    // },
    // {
    //   icon: 'FaUserTie',
    //   route: '/relatorios/relatorio-consorcio6',
    //   label: 'Extrato Pgto PJ',
    //   roles: ['ADMIN'],
    // },

    // {
    //   icon: 'FaUserTie',
    //   route: '/relatorios/relatorio-consorcio9',
    //   label: 'Cielo',
    //   roles: ['ADMIN'],
    // },
    // {
    //   icon: 'FaUserTie',
    //   route: '/relatorios/relatorio-consorcio7',
    //   label: 'Ourocap',
    //   roles: ['ADMIN'],
    // },
    // {
    //   icon: 'FaUserTie',
    //   route: '/relatorios/relatorio-contas',
    //   label: 'Contas',
    //   roles: ['ADMIN'],
    // },
  ],
};
const empresas: Menu = {
  title: 'Gestão de Empresas',
  icon: 'MdBusiness',
  roles: ['ADMIN'],
  checkRoute: 'cadastro-empresa',
  children: [
    {
      icon: 'FaUserTie',
      route: '/cadastro-empresa/empresas',
      label: 'Cadastro Empresa',
      roles: ['ADMIN'],
    },
  ],
};

const nav: Nav = {
  items: [
    dashboard,
    cadastros,
    cadastrosoperacional,
    cadastroregional,
    clientes,
    // documentos,
    // financeiro,
    // gestao,
    // mailing,
    produtos,
    relatorios,
    taxas,
    empresas,
  ],
};

export default nav;
